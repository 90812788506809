import Carousel from 'react-bootstrap/Carousel';
import './carousel.scss';
/** Natural Farming */
import image1 from '../../../assets/EmpoweringSHGs/DSC_0873.JPG';
import image2 from '../../../assets/EmpoweringSHGs/DSC_0895.JPG';
import image3 from '../../../assets/EmpoweringSHGs/DSC_0896.JPG';
import image4 from '../../../assets/EmpoweringSHGs/DSC_0897.JPG';
import image5 from '../../../assets/EmpoweringSHGs/DSC_7342.JPG';
import image6 from '../../../assets/EmpoweringSHGs/DSC_7343.JPG';
import image7 from '../../../assets/EmpoweringSHGs/DSC_7344.JPG';
import image8 from '../../../assets/EmpoweringSHGs/DSC_7345.JPG';

/** Skill Development */
import rem1 from '../../../assets/EmpoweringSHGs/9.JPG';
import rem2 from '../../../assets/EmpoweringSHGs/Jwelerymaking/1.jpg';
import rem3 from '../../../assets/EmpoweringSHGs/Jwelerymaking/2.jpg';
import rem4 from '../../../assets/EmpoweringSHGs/Jwelerymaking/3.jpg';
import rem5 from '../../../assets/EmpoweringSHGs/Jwelerymaking/4.jpeg';
import rem6 from '../../../assets/EmpoweringSHGs/Jwelerymaking/5.jpeg';
import rem7 from '../../../assets/EmpoweringSHGs/Jwelerymaking/6.jpeg';

function WomenCarousel(props) {

  const {tabElement}=props;

  return (
    <div class="educationCarousel aboutUsSlide">
    <Carousel fade>
      <Carousel.Item interval={1500}>
        <img src={image1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image3} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image5} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image6} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image7} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image8} class="d-block w-100" alt="..." />
        </Carousel.Item>
      <Carousel.Item interval={1500}>
        <img src={rem1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem3} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem7} class="d-block w-100" alt="..." />
        </Carousel.Item>
        
    </Carousel>
    </div>
  );
}

export default WomenCarousel;