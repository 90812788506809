import Carousel from 'react-bootstrap/Carousel';
import './carousel.scss';
/** Natural Farming */
import image1 from '../../../assets/CareAndProtection/DSC_2557.JPG';
import image2 from '../../../assets/CareAndProtection/DSC_2559.JPG';
import image3 from '../../../assets/CareAndProtection/DSC_2560.JPG';
import image4 from '../../../assets/CareAndProtection/DSC_2563.JPG';
import image5 from '../../../assets/CareAndProtection/DSC_2566.JPG';
import image6 from '../../../assets/CareAndProtection/DSC_2569.JPG';
import image7 from '../../../assets/CareAndProtection/DSC_2608.JPG';
import image8 from '../../../assets/CareAndProtection/DSC_2622.JPG';
import image9 from '../../../assets/CareAndProtection/DSC_3335.JPG';
import image10 from '../../../assets/CareAndProtection/DSC_3429.JPG';
import image11 from '../../../assets/CareAndProtection/DSC_3430.JPG';
import image12 from '../../../assets/CareAndProtection/DSC_3432.JPG';

/** Skill Development */
import rem1 from '../../../assets/CareAndProtection/DSC_3436.JPG';
import rem2 from '../../../assets/CareAndProtection/DSC_4039 (1).JPG';
import rem3 from '../../../assets/CareAndProtection/DSC_4039.JPG';
import rem4 from '../../../assets/CareAndProtection/DSC_4055.JPG';

function CareProtectionCarousel(props) {

  const {tabElement}=props;

  return (
    <div class="educationCarousel aboutUsSlide">
    <Carousel fade>
      <Carousel.Item interval={1500}>
        <img src={image1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image3} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image5} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image6} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image7} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image8} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image9} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image10} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image11} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image12} class="d-block w-100" alt="..." />
        </Carousel.Item>
      <Carousel.Item interval={1500}>
        <img src={rem1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem3} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        
    </Carousel>
    </div>
  );
}

export default CareProtectionCarousel;