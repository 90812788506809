import { Container, Row, Col } from "react-bootstrap";
import "./ResourceStyle.scss"
import Rpt1 from "../../assets/LegalDocuments/KMSWS Pan.pdf"
import Rpt2 from "../../assets/LegalDocuments/KMSWS computerised registration certificate.pdf"
import Rpt3 from "../../assets/LegalDocuments/Fcra renewal certificate.pdf"
import Rpt4 from "../../assets/LegalDocuments/KMSWS FCRA certificate.pdf"
import Rpt5 from "../../assets/LegalDocuments/NITI Ayog 1.pdf"
import {Eye, FilePdf} from "react-bootstrap-icons";

const LegalDocuments = () => {
    
    return (
      <>
        <Container>
        <Row>
            <Col style={{textAlign:'center'}}>
                <h1>Legal Documents</h1> <br></br>
            </Col>
        </Row>
            <Row>
                <Col style={{textAlign:'center'}} xs={12} md={4} xl={4}>
                <a href={Rpt1} target="_blank" title="View Report"><FilePdf className="pdfIcon" /></a>
                <h3>PAN Copy &nbsp; <a href={Rpt1} target="_blank" title="View Report"><Eye /></a></h3>
                    <iframe className="iframeReport" src={Rpt1}></iframe>
                </Col>
                <Col style={{textAlign:'center'}} xs={12} md={4} xl={4}>
                <a href={Rpt2} target="_blank" title="View Report"><FilePdf className="pdfIcon" /></a>
                <h3>Society Registration Copy&nbsp; <a href={Rpt2} target="_blank" title="View Report"><Eye /></a></h3>
                    <iframe className="iframeReport" src={Rpt2}></iframe>
                </Col>
                <Col style={{textAlign:'center'}} xs={12} md={4} xl={4}>
                <a href={Rpt3} target="_blank" title="View Report"><FilePdf className="pdfIcon" /></a>
                <h3>FCRA Renewal Copy&nbsp; <a href={Rpt3} target="_blank" title="View Report"><Eye /></a></h3>
                    <iframe className="iframeReport" src={Rpt3}></iframe>
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign:'center'}} xs={12} md={4} xl={4}>
                <a href={Rpt4} target="_blank" title="View Report"><FilePdf className="pdfIcon" /></a>
                <h3>FCRA Registration Copy&nbsp; <a href={Rpt4} target="_blank" title="View Report"><Eye /></a></h3>
                    <iframe className="iframeReport" src={Rpt4}></iframe>
                </Col>
                <Col style={{textAlign:'center'}} xs={12} md={5} xl={5}>
                <a href={Rpt5} target="_blank" title="View Report"><FilePdf className="pdfIcon" /></a>
                <h3>NITI Aayog NGO Darpan Copy&nbsp; <a href={Rpt5} target="_blank" title="View Report"><Eye /></a></h3>
                    <iframe className="iframeReport" src={Rpt5}></iframe>
                </Col>
            </Row>
            <br></br>
        </Container>
      </>
    )
  };
  
  export default LegalDocuments;