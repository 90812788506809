import Carousel from 'react-bootstrap/Carousel';
import './carousel.scss';
/** Natural Farming */
import image1 from '../../../assets/Disasterresponse/DSC_0084.JPG';
import image2 from '../../../assets/Disasterresponse/DSC_0090.JPG';
import image3 from '../../../assets/Disasterresponse/DSC_0093.JPG';
import image4 from '../../../assets/Disasterresponse/DSC_0104.JPG';
import image5 from '../../../assets/Disasterresponse/DSC_0108.JPG';
import image6 from '../../../assets/Disasterresponse/DSC_0116.JPG';
import image7 from '../../../assets/Disasterresponse/DSC_0117.JPG';
import image8 from '../../../assets/Disasterresponse/DSC_0124.JPG';
import image9 from '../../../assets/Disasterresponse/DSC_0895.JPG';
import image10 from '../../../assets/Disasterresponse/DSC_7413.JPG';
import image11 from '../../../assets/Disasterresponse/DSC_7428.JPG';
import image12 from '../../../assets/Disasterresponse/DSC_7543.JPG';

/** Skill Development */
import rem1 from '../../../assets/Disasterresponse/2.jpeg';
import rem2 from '../../../assets/Disasterresponse/5.jpeg';
import rem3 from '../../../assets/Disasterresponse/8.jpeg';
import rem4 from '../../../assets/Disasterresponse/10.jpeg';
import rem5 from '../../../assets/Disasterresponse/DSC_0878.JPG';
import rem6 from '../../../assets/Disasterresponse/DSC_0886.JPG';
import rem7 from '../../../assets/Disasterresponse/DSC_0890.JPG';
import rem8 from '../../../assets/Disasterresponse/DSC_0894.JPG';
import rem9 from '../../../assets/Disasterresponse/DSC_7471.JPG';
import rem10 from '../../../assets/Disasterresponse/DSC_7474.JPG';
import rem11 from '../../../assets/Disasterresponse/DSC_7541.JPG';

function EnvironmentCarousel(props) {

  const {tabElement}=props;

  return (
    <div class="educationCarousel aboutUsSlide">
    <Carousel fade>
      <Carousel.Item interval={1500}>
        <img src={image1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image3} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image5} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image6} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image7} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image8} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image9} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image10} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image11} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image12} class="d-block w-100" alt="..." />
        </Carousel.Item>
      <Carousel.Item interval={1500}>
        <img src={rem1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem3} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem5} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem6} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem7} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem8} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem9} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem10} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem11} class="d-block w-100" alt="..." />
        </Carousel.Item>
        
    </Carousel>
    </div>
  );
}

export default EnvironmentCarousel;