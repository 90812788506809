import Carousel from 'react-bootstrap/Carousel';
import './carousel.scss';
/** Natural Farming */
import image1 from '../../../assets/Agriculture/1.jpg';
import image2 from '../../../assets/Agriculture/2.jpg';
import image3 from '../../../assets/Agriculture/3.jpg';
import image4 from '../../../assets/Agriculture/4.jpg';
import image5 from '../../../assets/Agriculture/5.JPG';
import image6 from '../../../assets/Agriculture/6.JPG';
import image7 from '../../../assets/Agriculture/7.JPG';
import image8 from '../../../assets/Agriculture/8.JPG';

/** Skill Development */
import rem1 from '../../../assets/Vocationaltraining/DSC_0431.JPG';
import rem2 from '../../../assets/Vocationaltraining/DSC_0432.JPG';
import rem3 from '../../../assets/Vocationaltraining/DSC_0433.JPG';
import rem4 from '../../../assets/Vocationaltraining/DSC_0435.JPG';
import rem5 from '../../../assets/Vocationaltraining/DSC_0436.JPG';
import rem6 from '../../../assets/Vocationaltraining/DSC_0437.JPG';
import rem7 from '../../../assets/Vocationaltraining/DSC_0438.JPG';
import rem8 from '../../../assets/Vocationaltraining/DSC_0851.JPG';
import rem9 from '../../../assets/Vocationaltraining/DSC_0441.JPG';
import rem10 from '../../../assets/Vocationaltraining/DSC_0849.JPG';
import rem11 from '../../../assets/Vocationaltraining/DSC_0850.JPG';

function LivelihoodCarousel(props) {

  const {tabElement}=props;

  return (
    <div class="educationCarousel aboutUsSlide">
    {tabElement === "Natural Farming" && ( 
    <Carousel fade>
      <Carousel.Item interval={1500}>
        <img src={image1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        {/* <Carousel.Item interval={1500}>
        <img src={image3} class="d-block w-100" alt="..." />
        </Carousel.Item> */}
        <Carousel.Item interval={1500}>
        <img src={image4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image5} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image6} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image7} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image8} class="d-block w-100" alt="..." />
        </Carousel.Item>
    </Carousel> )}


    {tabElement === "Skill Development" && ( 
    <Carousel fade>
      <Carousel.Item interval={1500}>
        <img src={rem1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem3} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem5} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem6} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem7} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem8} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem9} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem10} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem11} class="d-block w-100" alt="..." />
        </Carousel.Item>
    </Carousel> )}
    </div>
  );
}

export default LivelihoodCarousel;