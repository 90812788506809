import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HomeText from '../contentFiles/HomeText.json'
import ContactText from '../contentFiles/ContactText.json';
import { Facebook, Instagram, Linkedin, TwitterX,Youtube, PhoneVibrate,EnvelopeAt } from 'react-bootstrap-icons';

const Contact = () => {
    return(
      <Container fluid className="aboutContainer">
      <Row>
        <Col className="textAlignCenter"><h1>{ContactText.contactUs}</h1><br></br></Col>
      </Row>
      <Row>
        <Col lg={6} xl={6}>
        {/* <Card className="customcard"> */}
       {/*  <iframe
                src={ContactText.googleMap}
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                width="600"
                height="450"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
            /> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3692.437795366323!2d88.22558450000001!3d22.261400100000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a025e98758806fb%3A0x4009181f01ea9f33!2sKankura%20Masat%20Social%20Welfare%20Society!5e0!3m2!1sen!2sin!4v1683700190033!5m2!1sen!2sin" className='iFrameMap' style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                
        </Col>
        <Col xs={12} md={12} lg={4} xl={4} style={{textAlign:"left"}}>
          <p>{ContactText.addressLine1}<br></br>
          {ContactText.addressLine2}<br></br>
          {ContactText.addressLine3}<br></br>
          {ContactText.pin}</p>
          <p><PhoneVibrate size={'26px'} style={{color:"#1ac5ad"}} />&nbsp;{ContactText.phone}</p>
          <p><EnvelopeAt style={{color:"#1ac5ad"}} />&nbsp;<a href={'mailto:'+ContactText.email}>{ContactText.email}</a></p>
        </Col>
        <Col>
        <div className="socialMediaIcons">
        <Facebook className="mrgnRght facebook" onClick={()=>window.open(HomeText.facebook,'_blank')}/>
        <Instagram className="mrgnRght instagram" onClick={()=>window.open(HomeText.instagram,'_blank')}/>
        <Linkedin className="mrgnRght linkedin" onClick={()=>window.open(HomeText.linkedin,'_blank')}/>
        <TwitterX className="mrgnRght twitter" onClick={()=>window.open(HomeText.twitter,'_blank')}/>
        <Youtube className="mrgnRght youtube" onClick={()=>window.open(HomeText.youtube,'_blank')}/>
        </div>
        </Col>
      </Row>
    </Container>
    )
    
  };
  
  export default Contact;