import Carousel from 'react-bootstrap/Carousel';
import './carousel.scss';
/** Natural Farming */
import image1 from '../../../assets/Health/DSC_0190.JPG';
import image2 from '../../../assets/Health/DSC_0200.JPG';
import image3 from '../../../assets/Health/DSC_0207.JPG';
import image4 from '../../../assets/Health/DSC_0209.JPG';
import image5 from '../../../assets/Health/DSC_0387.JPG';
import image6 from '../../../assets/Health/DSC_0393.JPG';
import image7 from '../../../assets/Health/DSC_0394.JPG';
import image8 from '../../../assets/Health/DSC_0449.JPG';

/** Skill Development */
import rem1 from '../../../assets/Health/DSC_3175.JPG';
import rem2 from '../../../assets/Health/DSC_3181.JPG';
import rem3 from '../../../assets/Health/DSC_3192.JPG';
import rem4 from '../../../assets/Health/DSC_3200.JPG';
import rem5 from '../../../assets/Health/DSC_3429.JPG';
import rem6 from '../../../assets/Health/DSC_3430.JPG';
import rem7 from '../../../assets/Health/DSC_3432.JPG';
import rem8 from '../../../assets/Health/DSC_3436.JPG';
import rem9 from '../../../assets/Health/DSC_6324.JPG';
import rem10 from '../../../assets/Health/DSC_6326.JPG';
import rem11 from '../../../assets/Health/DSC_6339.JPG';

function HealthCarousel(props) {

  const {tabElement}=props;

  return (
    <div class="educationCarousel aboutUsSlide">
    {tabElement === "Health Camp" && ( 
    <Carousel fade>
      <Carousel.Item interval={1500}>
        <img src={image1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image3} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image5} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image6} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image7} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image8} class="d-block w-100" alt="..." />
        </Carousel.Item>
    </Carousel> )}


    {tabElement === "PMJAY" && ( 
    <Carousel fade>
      <Carousel.Item interval={1500}>
        <img src={rem1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem3} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem5} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem6} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem7} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem8} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem9} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem10} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem11} class="d-block w-100" alt="..." />
        </Carousel.Item>
    </Carousel> )}
    </div>
  );
}

export default HealthCarousel;