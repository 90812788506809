import React, { useState } from 'react';
import './Donate.scss'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Hand from '../../assets/Hand.svg';
import donate from '../../assets/donate.png';
import QR from '../../assets/qrDonate/QR_code.jpeg'
import GetInvolved from '../../contentFiles/GetInvolved.json';
import TestimonialCard from '../TestimonialCard'

function DonateUsModal(props) {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
    <Button variant="info" className="donateButton" onClick={handleShow}>Donate Now</Button>
    <Modal show={show} onHide={handleClose} className="donateModal">
        <Modal.Header closeButton>
          <Modal.Title><img src={donate} width={'50px'} /><b style={{color:'#1ac5ad'}}>Become A Smiling Partner Today & You Can Help The Needy Community Just Like Many!</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
                {/* <Col lg={5} xl={3}>
                    <img src={QR} />
                </Col> */}
                <Col lg={12} xl={12}>
                    
                    <Card className={'testiCard testi1'} style={{ width: '100%',textAlign:'center',display:'inline-table' }}>
                        <Card.Body>
                            
                            <Card.Text>
                            <h6>{GetInvolved.donateUs.text}</h6><br></br>
                            <img src={QR} />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className="okButtonModal">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      </>
  );
}


export default DonateUsModal;