import Carousel from 'react-bootstrap/Carousel';
import './carousel.scss';
/** Tiyas School */
import image1 from '../../../assets/TiyasSchool/DSC_0144 (2).JPG';
import image2 from '../../../assets/TiyasSchool/DSC_0144.JPG';
import image3 from '../../../assets/TiyasSchool/DSC_0161.JPG';
import image4 from '../../../assets/TiyasSchool/DSC_0183 (1).JPG';
import image5 from '../../../assets/TiyasSchool/DSC_0205.JPG';
import image6 from '../../../assets/TiyasSchool/DSC_0267 (1).JPG';
import image7 from '../../../assets/TiyasSchool/DSC_0771.JPG';
import image8 from '../../../assets/TiyasSchool/DSC_0851.JPG';
import image9 from '../../../assets/TiyasSchool/DSC_1286 (1).JPG';
import image10 from '../../../assets/TiyasSchool/DSC_1286.JPG';
import image11 from '../../../assets/TiyasSchool/DSC_1295.JPG';
import image12 from '../../../assets/TiyasSchool/DSC_1312.JPG';
import image13 from '../../../assets/TiyasSchool/DSC_2075.JPG';

/** Remedial Education */
import rem1 from '../../../assets/RemedialEducation/DSC_0225.JPG';
import rem2 from '../../../assets/RemedialEducation/DSC_0233.JPG';
import rem3 from '../../../assets/RemedialEducation/DSC_0247.JPG';
import rem4 from '../../../assets/RemedialEducation/DSC_0249.JPG';
import rem5 from '../../../assets/RemedialEducation/DSC_0593.JPG';
import rem6 from '../../../assets/RemedialEducation/DSC_0605.JPG';
import rem7 from '../../../assets/RemedialEducation/DSC_0606.JPG';
import rem8 from '../../../assets/RemedialEducation/DSC_0626.JPG';
import rem9 from '../../../assets/RemedialEducation/DSC_0813 (2).JPG';
import rem10 from '../../../assets/RemedialEducation/DSC_0821.JPG';
import rem11 from '../../../assets/RemedialEducation/DSC_1489 (1).JPG';

/** Scholarship Support */
import sch1 from '../../../assets/Scholarshipdistribution/DSC_2478.JPG';
import sch2 from '../../../assets/Scholarshipdistribution/DSC_2481.JPG';
import sch3 from '../../../assets/Scholarshipdistribution/DSC_2482.JPG';
import sch4 from '../../../assets/Scholarshipdistribution/DSC_2584.JPG';
import sch5 from '../../../assets/Scholarshipdistribution/DSC_2591.JPG';
import sch6 from '../../../assets/Scholarshipdistribution/DSC_2592.JPG';
import sch7 from '../../../assets/Scholarshipdistribution/DSC_2593.JPG';
import sch8 from '../../../assets/Scholarshipdistribution/DSC_2594.JPG';
import sch9 from '../../../assets/Scholarshipdistribution/DSC_2598.JPG';
import sch10 from '../../../assets/Scholarshipdistribution/DSC_2600.JPG';
import sch11 from '../../../assets/Scholarshipdistribution/DSC_2601.JPG';
import sch12 from '../../../assets/Scholarshipdistribution/DSC_2602.JPG';
import sch13 from '../../../assets/Scholarshipdistribution/DSC_2582.JPG';

/** Career Councelling */
import cc1 from '../../../assets/CareerCounselling/1.jpeg';
import cc2 from '../../../assets/CareerCounselling/2.jpeg';
import cc3 from '../../../assets/CareerCounselling/3.jpeg';
import cc4 from '../../../assets/CareerCounselling/4.jpeg';
import cc5 from '../../../assets/CareerCounselling/5.jpeg';
import cc6 from '../../../assets/CareerCounselling/6.jpeg';
import cc7 from '../../../assets/CareerCounselling/7.jpeg';
import cc8 from '../../../assets/CareerCounselling/8.jpeg';
import cc9 from '../../../assets/CareerCounselling/9.jpeg';
import cc10 from '../../../assets/CareerCounselling/10.jpeg';

function EducationCarousel(props) {

  const {tabElement}=props;

  return (
    <div class="educationCarousel aboutUsSlide">
    {tabElement === "Tiyas School" && ( 
    <Carousel fade>
      <Carousel.Item interval={1500}>
        <img src={image1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image3} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image5} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image6} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image7} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image8} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image9} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image10} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image11} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image12} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image13} class="d-block w-100" alt="..." />
        </Carousel.Item>
    </Carousel> )}


    {tabElement === "Remedial Education" && ( 
    <Carousel fade>
      <Carousel.Item interval={1500}>
        <img src={rem1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem3} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem5} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem6} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem7} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem8} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem9} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem10} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={rem11} class="d-block w-100" alt="..." />
        </Carousel.Item>
    </Carousel> )}


    {tabElement === "Scholarship Support" && ( 
    <Carousel fade>
      <Carousel.Item interval={1500}>
        <img src={sch1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={sch2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={sch3} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={sch4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={sch5} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={sch6} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={sch7} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={sch8} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={sch9} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={sch10} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={sch11} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={sch12} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={sch13} class="d-block w-100" alt="..." />
        </Carousel.Item>
    </Carousel> )}


    {tabElement === "Career Counselling Support" && ( 
    <Carousel fade>
      <Carousel.Item interval={1500}>
        <img src={cc1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={cc2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={cc3} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={cc4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={cc5} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={cc6} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={cc7} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={cc8} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={cc9} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={cc10} class="d-block w-100" alt="..." />
        </Carousel.Item>
    </Carousel> )}
    </div>
  );
}

export default EducationCarousel;